//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'

export default {
    data() {
        return {
            mounted: false,
            settings: {
                arrows: false,
                dots: false,
                infinite: true,
                speed: 10000,
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplay: true,
                autoplaySpeed: 0,
                pauseOnFocus: true,
                pauseOnHover: true,
                variableWidth: true,
                cssEase: 'linear',
            }
        };
    },
    components: {
        VueSlickCarousel,
    },
    props: {
        blok: {
            type: Object,

            default: () => { }
        }
    },
    mounted() {
    },
    computed: {

    }
};
